import React from "react";
import { Input } from "antd";
import "./GetInTInput.css";

const GetInTInput = ({ text, bordr, name, onChange, value, icon }) => {
  return (
    <div className={`${bordr} input-container`}>
      <span className="input-label">
        {icon && <span className="input-icon">{icon}</span>}
        {/* {text} */}
      </span>
      <div className="input-icon-wrapper">
        <Input
          name={name}
          value={value}
          onChange={onChange}
          placeholder={text}
          style={{
            color: "inherit",
            paddingLeft: icon ? "2rem" : "0.5rem",
          }}
        />
      </div>
    </div>
  );
};

export default GetInTInput;
