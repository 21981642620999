import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "./LocationSlide.css";

const LocationSlider = ({ homePage, uniqueLocations }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 640);
  const [currentIndex, setCurrentIndex] = useState(0);

  const slides = [1, 2, 3, 4, 5];

  // Calculate the transform percentage
  const slideWidthPercent = 100 / uniqueLocations?.length;
  const transformPercentage = -(currentIndex * slideWidthPercent);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === uniqueLocations?.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? uniqueLocations?.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 640);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // console.log(uniqueLocations);

  return (
    <>
      <div className="row location_slide_wrap mt-5">
        <div className="col-12">
          <div
            className="swiper"
            style={{
              overflowX: isMobile ? "scroll" : "",
            }}
          >
            <div
              className="swiper-slides"
              style={{ transform: `translateX(${transformPercentage}%)` }}
            >
              {uniqueLocations && uniqueLocations.length > 0
                ? uniqueLocations.map((slide, index) => (
                    <div key={index} className="slide">
                      <div className="location_wrap">
                        <Link to={`/properties-by-location/${slide}`}>
                          {slide.toUpperCase().includes("IKOYI") && (
                            <img
                              src="./IKOYI.jpg"
                              alt="Ikoyi"
                              className="w-100"
                            />
                          )}
                          {slide.toUpperCase().includes("VICTORIA ISLAND") && (
                            <img
                              src="./VICTORIAISLAND.JPG"
                              alt="Victoria Island"
                              className="w-100"
                            />
                          )}
                          {slide.toUpperCase().includes("LEKKI PHASE 1") && (
                            <img
                              src="./LEKKIPHASE1.JPG"
                              alt="Lekki Phase 1"
                              className="w-100"
                            />
                          )}
                          {slide.toUpperCase().includes("IKATE") && (
                            <img
                              src="./IKATE.JPEG"
                              alt="Ikate"
                              className="w-100"
                            />
                          )}
                          {![
                            "IKOYI",
                            "VICTORIA ISLAND",
                            "LEKKI PHASE 1",
                            "IKATE",
                          ].some((loc) =>
                            slide.toUpperCase().includes(loc)
                          ) && (
                            <img
                              src="./location-img.jpg"
                              alt="Default Location"
                              className="w-100"
                            />
                          )}
                          <div className="location_rotate">
                            <span>{slide.toUpperCase()}</span>
                          </div>
                        </Link>
                      </div>
                    </div>
                  ))
                : [1, 2, 3, 4].map((_, index) => (
                    <div key={index} className="slide">
                      <div className="location_wrap">
                        <img
                          src="./default_img.png"
                          alt="Default Location"
                          className="w-100"
                        />
                        <div className="location_rotate">
                          <span>Please! Check Back</span>
                        </div>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>

      {uniqueLocations?.length > 0 ? (
        <div className="row">
          <div className="col-12">
            <div className="row justify-content-center my-2 mt-5">
              <div className="col-2 d-flex justify-content-center gap-2 mt-4">
                <button
                  className="btn btn-outline-dark"
                  style={{
                    border: "1px solid #F4F4F4",
                    cursor: "pointer",
                    borderRadius: "2rem",
                    backgroundColor: "#F4F4F4",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "0.7rem 0.8rem",
                  }}
                  onClick={prevSlide}
                  disabled={uniqueLocations.length < 5}
                >
                  <img src="./arrow-down.png" alt="line-img" />
                  {/* <FaLongArrowAltLeft /> */}
                </button>
                <button
                  onClick={nextSlide}
                  disabled={uniqueLocations.length < 5}
                  className="btn btn-outline-dark "
                  style={{
                    border: "1px solid #F4F4F4",
                    cursor: "pointer",
                    borderRadius: "2rem",
                    backgroundColor: "#F4F4F4",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "0.7rem 0.8rem",
                  }}
                >
                  <img src="./arrow-down-1.png" alt="line-img" />
                  {/* <FaLongArrowAltRight /> */}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default LocationSlider;

// <Swiper
//   spaceBetween={11}
//   slidesPerView={isMobile ? 2 : 5}
//   navigation
//   autoplay={{ delay: 4000, disableOnInteraction: false }}
//   onSlideChange={() => console.log("slide change")}
//   onSwiper={(swiper) => console.log(swiper)}
// >
//   {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((Item, i) => {
//     return (
//       <SwiperSlide key={i} className="mx-2">
//         <div className="location_wrap">
//           <img src="./location-img.png" alt="" className="w-100" />
//           <div className="location_rotate">
//             <span>VICTORIA ISLAND</span>
//           </div>
//         </div>
//       </SwiperSlide>
//     );
//   })}
// </Swiper>
//
