import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Properties from "./Pages/Properties";
import Projects from "./Pages/Projects";
import Blogs from "./Pages/Blogs";
import AboutUs from "./Pages/AboutUs";
import PropertyRequested from "./Pages/PropertyRequested";
import PropertySelected from "./Pages/PropertySelected";
import SelectedProject from "./Pages/SelectedProject";
import SelectedBlog from "./Pages/SelectedBlog";
import PageTransition from "./PageTransition";
import SearchResult from "./Pages/SearchResult";
import AllLocationProps from "./Pages/AllLocationProps";
import ContactUs from "./Pages/ContactUs";
import UsePageTracking from "./usePageTracking";

function App() {
  return (
    <Router>
      <UsePageTracking />
      <Routes>
        <Route
          path="/"
          element={
            // <PageTransition>
            <Home />
            // </PageTransition>
          }
        />
        <Route
          path="/properties"
          element={
            // <PageTransition>
            <Properties />
            // </PageTransition>
          }
        />
        <Route
          path="/projects"
          element={
            // <PageTransition>
            <Projects />
            // </PageTransition>
          }
        />
        <Route
          path="/blogs"
          element={
            // <PageTransition>
            <Blogs />
            // </PageTransition>
          }
        />
        <Route
          path="/about-us"
          element={
            // <PageTransition>
            <AboutUs />
            // </PageTransition>
          }
        />
        <Route
          path="/property-reqed"
          element={
            // <PageTransition>
            <PropertyRequested />
            // </PageTransition>
          }
        />
        <Route
          path="/property/:slug"
          element={
            // <PageTransition>
            <PropertySelected />
            // </PageTransition>
          }
        />
        <Route
          path="/project/:slug"
          element={
            // <PageTransition>
            <SelectedProject />
            // </PageTransition>
          }
        />
        <Route
          path="/selected-blog/:slug"
          element={
            // <PageTransition>
            <SelectedBlog />
            // </PageTransition>
          }
        />
        <Route
          path="/search"
          element={
            // <PageTransition>
            <SearchResult />
            // </PageTransition>
          }
        />
        <Route
          path="/contact-us"
          element={
            // <PageTransition>
            <ContactUs />
            // </PageTransition>
          }
        />
        <Route
          path="/properties-by-location/:location"
          element={
            // <PageTransition>
            <AllLocationProps />
            // </PageTransition>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
