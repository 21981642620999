import React from "react";
import { Link } from "react-router-dom";
import "./Home_footer.css";

const Home_footer = () => {
  return (
    <footer className="home_footer">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="footer-left">
              <img src="/Logo-svg.svg" alt="logo" width="70" />
            </div>
          </div>
        </div>
        <div className="row justify-content-between my-4">
          <div className="col-md-3">
            <p>
              {" "}
              Copyright © 2025 Joe Etoniru & Associates. All rights reserved.
            </p>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-4">
                <div className="footer-middle">
                  <ul className="footer-links">
                    <li>
                      <Link to="/home">Home</Link>
                    </li>
                    <li>
                      <Link to="/properties">Properties</Link>
                    </li>
                    <li>
                      <Link to="/projects">Projects</Link>
                    </li>
                    <li>
                      <Link to="/blogs">Blogs</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4">
                <div className="footer-middle">
                  <ul className="footer-links">
                    <li>
                      <Link to="/about-us">About Us</Link>
                    </li>
                    <li>
                      <Link to="/contact-us">Get in Touch</Link>
                    </li>
                    <li>
                      <Link to="/#">Live Chat</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4">
                <div className="footer-right">
                  <ul className="social-links">
                    <li>
                      <Link to="/facebook">
                        Facebook <span className="arrow">&#8593;</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/instagram">
                        Instagram <span className="arrow">&#8593;</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/twitter">
                        Twitter <span className="arrow">&#8593;</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="row"
          style={{
            color: "#fff",
            marginTop: " 4rem",
          }}
        >
          <div className="col-md-2">
            <p>Privacy Policy</p>
          </div>
          <div className="col-md-2">
            <p>Terms of Service</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Home_footer;
