import React from "react";
import HighLightImg from "../../assets/images/hightlight.jpg"; // Updated path
import "./Featured_Projects.css"; // Import the CSS file
import { Link } from "react-router-dom";

const Featured_Projects = ({ completedProjs }) => {
  // console.log(completedProjs);

  return (
    <div className="container-fluid feactured_proj_home py-5 px-5">
      <div className="row m-4">
        <h2 className="">Featured Projects</h2>
      </div>
      <div>
        <p>
          We build Homes Designed to Feel Like Your Own. Discover our newly
          launched projects below in the heart of Lekki Phase 1{" "}
        </p>
      </div>
      <div className="row pt-4 px-4">
        {completedProjs && completedProjs.length > 0
          ? completedProjs.slice(0, 6).map((project) => {
              let phoneNumber;
              if (project?.agent_whatsapp.startsWith("234")) {
                // If what_num contains a country code, use it as is
                phoneNumber = project?.agent_whatsapp;
              } else {
                // If no country code is found, add 234 as the prefix
                phoneNumber = `234${project?.agent_whatsapp}`;
              }

              const itemName = project?.title; // Replace with the name of your product
              const itemUrl = `https://joetoniruest.com/property/${project?.slug}`; // Replace with the URL of your

              const handleClick = () => {
                const message = encodeURIComponent(
                  `Hi, I'm interested in the ${itemName} project. ${itemUrl}`
                );
                const href = `https://wa.me/${phoneNumber}?text=${message}`;
                window.open(href, "_blank");
              };
              return (
                <div className="col-md-4 mb-4" key={project?._id}>
                  <div
                    className="bg-image"
                    style={{
                      backgroundImage: `url(${
                        project?.images[1] ||
                        "https://res.cloudinary.com/dnd6g0t90/image/upload/v1725283324/r8mlssfi9r0oww0rrc7i.jpg"
                      })`,
                      // objectFit: "contain",
                    }}
                  >
                    <div className="overlay">
                      <div className="title">
                        {project?.title || "Discover Your Ideal Project"}
                      </div>
                      <a
                        href="#"
                        onClick={handleClick}
                        rel="noopener noreferrer"
                      >
                        <button
                          className="btn btn-primary mt-3 text-black"
                          style={{
                            background: "#fbc344",
                            border: "2px solid #fbc344",
                            paddingBottom: "8px",
                            paddingTop: "3px",
                          }}
                        >
                          Register Interest
                        </button>
                      </a>
                      <Link
                        to={`/project/${project?.slug}`}
                        style={{ color: "#fff", marginTop: "1rem" }}
                      >
                        Learn More
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })
          : [1, 2, 3, 4, 5, 6].map((_, index) => (
              <div className="col-md-4 mb-4 mt-1" key={index}>
                <div
                  className="bg-image"
                  style={{
                    backgroundImage: `url("https://res.cloudinary.com/dnd6g0t90/image/upload/v1724427783/yin5qnwr3pezhwfxll6y.jpg")`,
                    // objectFit: "contain",
                  }}
                >
                  <div className="overlay">
                    <div className="title">Discover Your Ideal Project</div>
                    <button
                      className="btn btn-primary mt-3 text-black"
                      style={{
                        background: "#fbc344",
                        border: "2px solid #fbc344",
                        paddingBottom: "8px",
                        paddingTop: "3px",
                      }}
                    >
                      Register Interest
                    </button>
                    <Link
                      to="/projects"
                      style={{ color: "#fff", marginTop: "1rem" }}
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
            ))}
      </div>
      <div className=" d-flex justify-content-center">
        {/* <Link to="/projects">
          {" "}
          <button
            type="button"
            className="btn btn-primary mt-5 mb-3"
            style={{
              background: "#fbc344",
              border: "2px solid #fbc344",
              fontWeight: "bold",
            }}
          >
            Register Interest
          </button>{" "}
        </Link> */}
      </div>
    </div>
  );
};

export default Featured_Projects;
