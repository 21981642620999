import React from "react";
import { Link } from "react-router-dom";

import ExampleCarouselImage from "../../assets/images/jea-about-2.png";
import "./about_home.css";

const About_section = () => {
  return (
    <div className="container about_home">
      <div className="row">
        <div className="col-md-3 col_top">
          <h3>Luxury Real Estate</h3>
        </div>
        <div className="col-md-5 col_middle">
          <div>
            <h2>Transforming Real Estate since 2012</h2>
          </div>
          <div className="">
            <p>
              Joe Etoniru & Associates is a boutique real estate firm delivering
              end-to-end solutions across the property value chain. From expert
              advisory and property valuations to brokerage and bespoke
              developments, we craft experiences tailored to your lifestyle and
              goals. With us, it’s more than real estate—it’s curated living.
            </p>
          </div>
          <div>
            <Link to={"/about-us"}>
              <button
                type="button"
                className="btn btn-primary text-dark"
                style={{
                  background: "#fbc344",
                  border: "2px solid #fbc344",
                  fontWeight: "bold",
                }}
              >
                Learn More
              </button>{" "}
            </Link>
          </div>
        </div>
        <div className="col-md-3 col_last">
          <div>
            <h3>...Curated Living, Tailored for You</h3>
          </div>
          <img src={ExampleCarouselImage} alt="img" />
        </div>
      </div>
    </div>
  );
};

export default About_section;
