import React, { useEffect, useState } from "react";
import AboutHero from "../components/Hero/AboutHero";
import { useDispatch, useSelector } from "react-redux";
import { allBlog, resetState } from "../features/Property/propertySlice";
import Home_footer from "../components/Home_Page_Addition/Home_footer";
import GetInTouch from "../components/GetInTouch/GetInTouch";

const ContactUs = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Scroll to the top when the component is mounted
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(resetState());
    dispatch(allBlog());
  }, [dispatch]);

  const [homePage, setHomePage] = useState(true);

  return (
    <>
      <AboutHero text="Contact Us" />

      <GetInTouch homePage={homePage} />
      <Home_footer />
    </>
  );
};
export default ContactUs;
