// Imports
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import Inputs from "../Inputs";
import { useFormik } from "formik";
import Select from "react-select";
import {
  filterPrperty,
  resetState,
} from "../../features/Property/propertySlice";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import SelectTwo from "../SelectTwo";
import SelectTwoNew from "../SelectTwoNew";
import FindProperties from "./FindProperties";

// Styles
import "./FindProp.css";
import "./search-home.css";

// Yup Schema
const schema = yup.object().shape({
  number_of_room: yup.number(),
  location: yup.string(),
  minPrice: yup.string(),
  maxPrice: yup.string(),
  // category: yup.string(),
});

const Search_home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const propertyState = useSelector((state) => state.property);

  // console.log(propertyState?.isLoading);

  const [category, setCategory] = useState("buy");

  const formik = useFormik({
    initialValues: {
      number_of_room: "",
      minPrice: "",
      maxPrice: "",
      location: "",
    },
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const ids = { ...values, category };
      // console.log(values.minPrice);

      try {
        // Perform your search logic and dispatch actions
        await dispatch(filterPrperty(ids));
        // Redirect to the search result page
        navigate("/search");
      } catch (error) {
        resetForm();
        setTimeout(() => {
          dispatch(resetState());
        }, 300);
        console.error("Error submitting form:", error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  //
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 460);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 640);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleNavSelect = (selectedKey) => {
    // Assuming you have an array of objects with keys and corresponding text
    const navItems = [
      { key: "first", text: "buy" },
      { key: "second", text: "rent" },
      { key: "third", text: "shortlet" },
      { key: "fourth", text: "land" },
    ];
    const selectedText = navItems.find(
      (item) => item.key === selectedKey
    )?.text;

    setCategory(selectedText);
  };

  useEffect(() => {
    setCategory("buy");
  }, []);

  // https://jea-backend.onrender.com/api/property/search?price[lte]=10000000&price[gte]=20000&location=Ikota&number_of_room=6&category=land

  const options = Array(10)
    .fill(0)
    .map((_, i) => ({
      label: ((i + 1) * 10000000).toLocaleString(),
      value: (i + 1) * 10000000,
    }));

  return (
    <div className="container-fluid search-home-2">
      <div className="row p-2">
        <div
          className="row  bg-white-2"
          style={{
            width: "80%",
            marginLeft: "10%",
            zIndex: "1000",
            borderRadius: "16px",
          }}
        >
          <div className="col mb-2">
            <h4
              style={{
                color: "#000",
              }}
              className="text-start"
            >
              Find Your Next Property
            </h4>
          </div>
          {/*  */}
          <div className="searc_home_tab ">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row className="top_row">
                <Col
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  className="mx-auto d-flex "
                  id="tab_1"
                >
                  <Nav
                    variant="pills"
                    className="flex-row"
                    onSelect={handleNavSelect}
                  >
                    <Nav.Item>
                      <Nav.Link
                        eventKey="first"
                        // className="py-2 pb-3 px-5xr mx-2"
                        style={{
                          fontWeight: "400",
                        }}
                      >
                        Buy
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="second"
                        // className="py-2 pb-3 px-5x mx-2"
                        style={{
                          fontWeight: "400",
                        }}
                      >
                        Rent
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="third"
                        // className="py-2 pb-3 px-5x mx-2"
                        style={{
                          fontWeight: "400",
                        }}
                      >
                        Shortlet
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="fourth"
                        // className="py-2 pb-3 px-5x mx-2"
                        style={{
                          fontWeight: "400",
                        }}
                      >
                        Land
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
              </Row>
              <Row className="bottom_row ">
                <Col lg={12} md={12} sm={12} className="text-dark mt-2">
                  <Tab.Content>
                    <Tab.Pane eventKey="first" className="py-2">
                      <form onSubmit={formik.handleSubmit}>
                        {" "}
                        <div className="row">
                          <div className="col-md-4 select-width">
                            {/* <SelectTwo
                              text={"No. of Bedrooms"}
                              widthx={"inherit"}
                              heightx={"inherit"}
                              name="number_of_room"
                              onChange={formik.handleChange}
                              value={formik.values.number_of_room}
                              items={[1, 2, 3, 4, 5, 6]}
                            /> */}
                            <SelectTwoNew
                              label="No. of Bedrooms"
                              name="number_of_room"
                              onChange={(event) =>
                                formik.setFieldValue(
                                  "number_of_room",
                                  event.target.value
                                )
                              }
                              value={formik.values.number_of_room}
                              options={[
                                { label: "1", value: 1 },
                                { label: "2", value: 2 },
                                { label: "3", value: 3 },
                                { label: "4", value: 4 },
                                { label: "5", value: 5 },
                                { label: "6", value: 6 },
                              ]}
                            />
                          </div>

                          <div className="col-md-4 select-width">
                            <SelectTwoNew
                              label="Min Price"
                              name="minPrice"
                              onChange={(event) =>
                                formik.setFieldValue(
                                  "minPrice",
                                  event.target.value
                                )
                              }
                              value={formik.values.minPrice}
                              options={[
                                ...[
                                  10, 20, 50, 100, 200, 500, 600, 700, 800, 900,
                                ].map((value) => ({
                                  label: (value * 1000000).toLocaleString(),
                                  value: value * 1000000,
                                })),
                                {
                                  label: (1000000000).toLocaleString(),
                                  value: 1000000000,
                                },
                              ]}
                            />
                          </div>

                          <div className="col-md-4 select-width">
                            <SelectTwoNew
                              label="Max Price"
                              name="maxPrice"
                              onChange={(event) =>
                                formik.setFieldValue(
                                  "maxPrice",
                                  event.target.value
                                )
                              }
                              // onChange={formik.handleChange}
                              value={formik.values.maxPrice}
                              options={[
                                ...Array(10)
                                  .fill(0)
                                  .map((_, i) => ({
                                    label: ((i + 1) * 5e8).toLocaleString(),
                                    value: (i + 1) * 5e8,
                                  })),
                              ]}
                            />
                            {/* <SelectComp widthx={"inherit"} heightx={"inherit"} /> */}
                          </div>
                        </div>
                        <div className="row mt-4 pt-1">
                          <div className="col-md-6">
                            {/* <Inputs text={"Enter a property description or location"} /> */}
                            <Inputs
                              text={"Enter a property description or location"}
                              name="location"
                              onChange={formik.handleChange}
                              value={formik.values.location}
                            />
                          </div>
                          <div className="col-md-6">
                            <button
                              id="search-btn"
                              className="button fit-content"
                              style={{
                                background: "#FBC344",
                                width: "100%",
                                color: "#000000",
                                borderRadius: "9px",
                              }}
                            >
                              {propertyState?.isLoading ? (
                                <div class="spinner-border" role="status">
                                  <span class="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              ) : (
                                "Search"
                              )}
                            </button>
                          </div>
                        </div>
                        {/* <div className="row my-4">
                        </div> */}
                      </form>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second" className="py-2">
                      <form onSubmit={formik.handleSubmit}>
                        {" "}
                        <div className="row">
                          <div className="col-md-4 select-width">
                            {/* <SelectTwo
                              text={"No. of Bedrooms"}
                              widthx={"inherit"}
                              heightx={"inherit"}
                              name="number_of_room"
                              onChange={formik.handleChange}
                              value={formik.values.number_of_room}
                              items={[1, 2, 3, 4, 5, 6]}
                            /> */}
                            <SelectTwoNew
                              label="No. of Bedrooms"
                              name="number_of_room"
                              onChange={(event) =>
                                formik.setFieldValue(
                                  "number_of_room",
                                  event.target.value
                                )
                              }
                              value={formik.values.number_of_room}
                              options={[
                                { label: "1", value: 1 },
                                { label: "2", value: 2 },
                                { label: "3", value: 3 },
                                { label: "4", value: 4 },
                                { label: "5", value: 5 },
                                { label: "6", value: 6 },
                              ]}
                            />
                          </div>

                          <div className="col-md-4 select-width">
                            <SelectTwoNew
                              label="Min Price"
                              name="minPrice"
                              onChange={(event) =>
                                formik.setFieldValue(
                                  "minPrice",
                                  event.target.value
                                )
                              }
                              value={formik.values.minPrice}
                              options={[
                                ...[1, 5, 10, 15, 20, 25, 30, 35, 40, 50].map(
                                  (value) => ({
                                    label: (value * 1000000).toLocaleString(),
                                    value: value * 1000000,
                                  })
                                ),
                              ]}
                            />
                          </div>

                          <div className="col-md-4 select-width">
                            <SelectTwoNew
                              label="Max Price"
                              name="maxPrice"
                              onChange={(event) =>
                                formik.setFieldValue(
                                  "maxPrice",
                                  event.target.value
                                )
                              }
                              // onChange={formik.handleChange}
                              value={formik.values.maxPrice}
                              options={[
                                ...Array(10)
                                  .fill(0)
                                  .map((_, i) => ({
                                    label: ((i + 1) * 5e7).toLocaleString(),
                                    value: (i + 1) * 5e7,
                                  })),
                              ]}
                            />
                            {/* <SelectComp widthx={"inherit"} heightx={"inherit"} /> */}
                          </div>
                        </div>
                        <div className="row mt-4 pt-1">
                          <div className="col-md-6">
                            {/* <Inputs text={"Enter a property description or location"} /> */}
                            <Inputs
                              text={"Enter a property description or location"}
                              name="location"
                              onChange={formik.handleChange}
                              value={formik.values.location}
                            />
                          </div>
                          <div className="col-md-6">
                            <button
                              id="search-btn"
                              className="button fit-content"
                              style={{
                                background: "#FBC344",
                                width: isMobile ? "80%" : "100%",
                                color: "#000000",
                                fontWeight: "400",
                                fontSize: "15px",
                                borderRadius: "9px",
                                padding: isMobile ? "10px" : "",
                              }}
                            >
                              {propertyState?.isLoading ? (
                                <div class="spinner-border" role="status">
                                  <span class="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              ) : (
                                "Search"
                              )}
                            </button>
                          </div>
                        </div>
                        {/* <div className="row my-4"></div> */}
                      </form>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third" className="py-2">
                      <form onSubmit={formik.handleSubmit}>
                        {" "}
                        <div className="row">
                          <div className="col-md-4 select-width">
                            {/* <SelectTwo
                              text={"No. of Bedrooms"}
                              widthx={"inherit"}
                              heightx={"inherit"}
                              name="number_of_room"
                              onChange={formik.handleChange}
                              value={formik.values.number_of_room}
                              items={[1, 2, 3, 4, 5, 6]}
                            /> */}
                            <SelectTwoNew
                              label="No. of Bedrooms"
                              name="number_of_room"
                              onChange={(event) =>
                                formik.setFieldValue(
                                  "number_of_room",
                                  event.target.value
                                )
                              }
                              value={formik.values.number_of_room}
                              options={[
                                { label: "1", value: 1 },
                                { label: "2", value: 2 },
                                { label: "3", value: 3 },
                                { label: "4", value: 4 },
                                { label: "5", value: 5 },
                                { label: "6", value: 6 },
                              ]}
                            />
                          </div>

                          <div className="col-md-4 select-width">
                            <SelectTwoNew
                              label="Min Price"
                              name="minPrice"
                              onChange={(event) =>
                                formik.setFieldValue(
                                  "minPrice",
                                  event.target.value
                                )
                              }
                              value={formik.values.minPrice}
                              options={[
                                ...Array(6)
                                  .fill(0)
                                  .map((_, i) => ({
                                    label: (i * 10000 + 50000).toLocaleString(),
                                    value: i * 10000 + 50000,
                                  })),
                                {
                                  label: (1000000000).toLocaleString(),
                                  value: 1000000000,
                                },
                              ]}
                            />
                          </div>

                          <div className="col-md-4 select-width">
                            <SelectTwoNew
                              label="Max Price"
                              name="maxPrice"
                              onChange={(event) =>
                                formik.setFieldValue(
                                  "maxPrice",
                                  event.target.value
                                )
                              }
                              // onChange={formik.handleChange}
                              value={formik.values.maxPrice}
                              options={[
                                ...Array(10)
                                  .fill(0)
                                  .map((_, i) => ({
                                    label: ((i + 1) * 5e5).toLocaleString(),
                                    value: (i + 1) * 5e5,
                                  })),
                                {
                                  label: (5000000).toLocaleString(),
                                  value: 5000000,
                                },
                              ]}
                            />
                            {/* <SelectComp widthx={"inherit"} heightx={"inherit"} /> */}
                          </div>
                        </div>
                        <div className="row mt-4 pt-1">
                          <div className="col-md-6">
                            {/* <Inputs text={"Enter a property description or location"} /> */}
                            <Inputs
                              text={"Enter a property description or location"}
                              name="location"
                              onChange={formik.handleChange}
                              value={formik.values.location}
                            />
                          </div>
                          <div className="col-md-6">
                            <button
                              id="search-btn"
                              className="button fit-content"
                              style={{
                                background: "#FBC344",
                                width: isMobile ? "80%" : "100%",
                                color: "#000000",
                                fontWeight: "400",
                                fontSize: "15px",
                                borderRadius: "9px",
                                padding: isMobile ? "10px" : "",
                              }}
                            >
                              {propertyState?.isLoading1 ? (
                                <div class="spinner-border" role="status">
                                  <span class="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              ) : (
                                "Search"
                              )}
                            </button>
                          </div>
                        </div>
                        {/* <div className="row my-4"></div> */}
                      </form>
                    </Tab.Pane>
                    <Tab.Pane eventKey="fourth" className="py-2">
                      <form onSubmit={formik.handleSubmit}>
                        {" "}
                        <div className="row">
                          <div className="col-md-4 select-width">
                            {/* <SelectTwo
                              text={"No. of Bedrooms"}
                              widthx={"inherit"}
                              heightx={"inherit"}
                              name="number_of_room"
                              onChange={formik.handleChange}
                              value={formik.values.number_of_room}
                              items={[1, 2, 3, 4, 5, 6]}
                            /> */}
                            <SelectTwoNew
                              label="No. of Bedrooms"
                              name="number_of_room"
                              onChange={(event) =>
                                formik.setFieldValue(
                                  "number_of_room",
                                  event.target.value
                                )
                              }
                              value={formik.values.number_of_room}
                              options={[
                                { label: "1", value: 1 },
                                { label: "2", value: 2 },
                                { label: "3", value: 3 },
                                { label: "4", value: 4 },
                                { label: "5", value: 5 },
                                { label: "6", value: 6 },
                              ]}
                            />
                          </div>

                          <div className="col-md-4 select-width">
                            <SelectTwoNew
                              label="Min Price"
                              name="minPrice"
                              onChange={(event) =>
                                formik.setFieldValue(
                                  "minPrice",
                                  event.target.value
                                )
                              }
                              value={formik.values.minPrice}
                              options={[
                                ...Array(10)
                                  .fill(0)
                                  .map((_, i) => ({
                                    label: (
                                      i * 500000 +
                                      500000
                                    ).toLocaleString(),
                                    value: i * 500000 + 500000,
                                  })),
                              ]}
                            />
                          </div>

                          <div className="col-md-4 select-width">
                            <SelectTwoNew
                              label="Max Price"
                              name="maxPrice"
                              onChange={(event) =>
                                formik.setFieldValue(
                                  "maxPrice",
                                  event.target.value
                                )
                              }
                              // onChange={formik.handleChange}
                              value={formik.values.maxPrice}
                              options={[
                                ...[
                                  5, 10, 50, 100, 200, 300, 500, 600, 700, 800,
                                  900,
                                ].map((value) => ({
                                  label: (value * 1e6).toLocaleString(),
                                  value: value * 1e6,
                                })),
                                {
                                  label: (1e9).toLocaleString(),
                                  value: 1e9,
                                },
                              ]}
                            />
                            {/* <SelectComp widthx={"inherit"} heightx={"inherit"} /> */}
                          </div>
                        </div>
                        <div className="row mt-4 pt-1">
                          <div className="col-md-6">
                            {/* <Inputs text={"Enter a property description or location"} /> */}
                            <Inputs
                              text={"Enter a property description or location"}
                              name="location"
                              onChange={formik.handleChange}
                              value={formik.values.location}
                            />
                          </div>
                          <div className="col-md-6">
                            <button
                              id="search-btn"
                              className="button fit-content"
                              style={{
                                background: "#FBC344",
                                width: isMobile ? "80%" : "100%",
                                color: "#000000",
                                fontWeight: "400",
                                fontSize: "15px",
                                borderRadius: "9px",
                                padding: isMobile ? "10px" : "",
                              }}
                            >
                              {propertyState?.isLoading ? (
                                <div class="spinner-border" role="status">
                                  <span class="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              ) : (
                                "Search"
                              )}
                            </button>
                          </div>
                        </div>
                        {/* <div className="row my-4"></div> */}
                      </form>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
          {/*  */}
        </div>
      </div>
    </div>
  );
};

export default Search_home;
{
  /* // search-Images.png */
}
