import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import DOMPurify from "dompurify";
import moment from "moment";

import "./New_Home_Blog.css";

const fallbackImage =
  "https://res.cloudinary.com/dnd6g0t90/image/upload/v1724427783/yin5qnwr3pezhwfxll6y.jpg";

const New_Home_Blog = ({ homeBg, blogPage, url_strblog, blogs }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 460);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 640);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // frame motion
  const headingRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (headingRef.current) {
        const headingTop = headingRef.current.offsetTop;
        const scrollPosition = window.scrollY + window.innerHeight;

        if (scrollPosition > headingTop) {
          setIsVisible(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const placeholders = [
    {
      date: new Date(),
      slug: "placeholder-1",
      title: {
        rendered:
          "The Art of Bespoke Developments: Tailoring Spaces to Your Vision",
      },
      content: {
        rendered:
          "<p>Stay ahead in the dynamic real estate market with insights from seasoned professionals.</p>",
      },
      _embedded: { "wp:featuredmedia": [{ source_url: fallbackImage }] },
    },
    {
      date: new Date(),
      slug: "placeholder-2",
      title: {
        rendered:
          "The Art of Bespoke Developments: Tailoring Spaces to Your Vision",
      },
      content: {
        rendered:
          "<p>Stay ahead in the dynamic real estate market with insights from seasoned professionals.</p>",
      },
      _embedded: { "wp:featuredmedia": [{ source_url: fallbackImage }] },
    },
    {
      date: new Date(),
      slug: "placeholder-3",
      title: {
        rendered:
          "The Art of Bespoke Developments: Tailoring Spaces to Your Vision",
      },
      content: {
        rendered:
          "<p>Stay ahead in the dynamic real estate market with insights from seasoned professionals.</p>",
      },
      _embedded: { "wp:featuredmedia": [{ source_url: fallbackImage }] },
    },
  ];

  const cardsData =
    blogs?.filter((blog) => blog.categories[0] === 4).slice(0, 3) || [];

  return (
    <div className="container-fluid New_Home_Blog">
      <div className="row justify-content-center">
        <div className=" top_item_blog">
          <h2>The Curated Life: Insights on Real Estate and Lifestyle</h2>
          <p>
            Dive into a world where real estate meets lifestyle. Explore expert
            advice, market trends, bespoke property solutions, and curated
            living ideas designed to inspire and elevate your journey.
          </p>
        </div>

        <Link to={"/blogs"}>
          <button
            type="button"
            className="btn btn-primary text-dark"
            style={{
              background: "#fbc344",
              border: "2px solid #fbc344",
              fontWeight: "bold",
            }}
          >
            Read Our Blog
          </button>
        </Link>
      </div>
      <div className="row">
        <div className="card-container">
          {blogs?.length > 0
            ? cardsData.map((blog, index) => {
                const sanitizedHTML = DOMPurify.sanitize(
                  blog?.content?.rendered
                );
                return (
                  <Link
                    to={`/selected-blog/${blog?.slug}`}
                    style={{ margin: "0 1rem" }}
                    key={index}
                  >
                    <Card className="card" style={{ margin: "1rem" }}>
                      <Card.Img
                        variant="top"
                        src={
                          blog?._embedded?.["wp:featuredmedia"][0]
                            ?.source_url || fallbackImage
                        }
                        style={{
                          width: "100%",
                          height: "300px",
                          borderRadius: "16px",
                        }}
                      />
                      <Card.Body
                        style={{
                          // backgroundColor: "#f9f9f9",
                          padding: "1rem 1.6rem",
                          minHeight: "80px",
                        }}
                        className="card_body py-3"
                      >
                        {/* <Card.Title>
                          <span style={{ fontSize: "12px", fontWeight: "400" }}>
                            {moment(blog?.date).format("MMMM Do YYYY")}
                          </span>
                        </Card.Title> */}
                        <div className="py-2 d-flex gap-2">
                          <span style={{ fontSize: "18px", fontWeight: "700" }}>
                            <b>{blog?.title?.rendered.slice(0, 47)}...</b>
                          </span>
                        </div>

                        <Card.Text
                          className="py-1"
                          style={{
                            fontSize: "15px",
                            fontWeight: "400",
                            color: "#121212",
                          }}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: sanitizedHTML.slice(0, 80) + "...",
                            }}
                          />
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Link>
                );
              })
            : placeholders.map((placeholder, index) => (
                <Link to={`/`} style={{ margin: "0 1rem" }} key={index}>
                  <Card className="card" style={{ margin: "1rem" }}>
                    <Card.Img
                      variant="top"
                      src={
                        placeholder._embedded["wp:featuredmedia"][0].source_url
                      }
                      style={{
                        width: "100%",
                        height: "300px",
                        borderRadius: "16px",
                      }}
                    />
                    <Card.Body
                      style={{
                        // backgroundColor: "#000",
                        padding: "0.6rem 1rem",
                        minHeight: "80px",
                      }}
                      className="card_body py-2"
                    >
                      {/* <Card.Title>
                        <span style={{ fontSize: "12px", fontWeight: "400" }}>
                          {moment(placeholder.date).format("MMMM Do YYYY")}
                        </span>
                      </Card.Title> */}
                      <div className="py-2 d-flex gap-2">
                        <span style={{ fontSize: "18px", fontWeight: "700" }}>
                          <b>{placeholder.title.rendered}</b>
                        </span>
                      </div>

                      <Card.Text
                        className="py-1"
                        style={{
                          fontSize: "15px",
                          fontWeight: "400",
                          color: "#121212",
                        }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: placeholder.content.rendered,
                          }}
                        />
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Link>
              ))}
        </div>
      </div>
    </div>
  );
};

export default New_Home_Blog;
