import React, { useEffect, useState } from "react";
import { FaUser, FaEnvelope, FaPhone } from "react-icons/fa";
import GetInTInput from "../GetInTInput/GetInTInput";
import Textareas from "../GetInTInput/Textareas";
import "./GetInTouch.css";
import * as yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { getIntouch, resetState } from "../../features/Property/propertySlice";
import { useDispatch, useSelector } from "react-redux";

const schema = yup.object().shape({
  phoneNumber: yup.string().required("Phone number is required"),
  additional_details: yup.string(),
  last_Name: yup.string().required("Last Name is required"),
  fisrt_Name: yup.string().required("First Name is required"),
  email: yup.string().email().required("Email is required"),
});

const GetInTouch = ({ homePage }) => {
  const dispatch = useDispatch();
  const propertyState = useSelector((state) => state.property);
  const formik = useFormik({
    initialValues: {
      additional_details: "",
      last_Name: "",
      fisrt_Name: "",
      phoneNumber: "",
      email: "",
    },
    validationSchema: schema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const data = values;
      try {
        await dispatch(getIntouch(data));
        formik.resetForm();
      } catch (error) {
        resetForm();
        setTimeout(() => {
          dispatch(resetState());
        }, 300);
        console.error("Error submitting form:", error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 460);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 640);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (propertyState?.isSuccess2 && propertyState?.postIntouchData) {
      toast.success("Details Sent! We'll get in touch with you.");
    }
  }, [propertyState?.isSuccess2, propertyState?.postIntouchData]);

  return (
    <div
      id="getInto"
      className="container-fluid get-in-touch"
      style={{
        backgroundColor: "#F9F9F9",
        padding: isMobile ? "15px" : "",
      }}
    >
      <div className="row gap-5 align-items-center">
        <div className="col-md-5 col-sm-12 display_none p-0 ">
          <img
            src="/getin_Frame.png"
            alt="Get in Touch"
            className="w-100"
            style={{
              height: "100%",
            }}
          />
        </div>
        <div className="col-md-6 col-sm-12 input-side d-flex flex-column justify-content-center">
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <h2 className="form-title">Get in Touch</h2>
              <p
                className="form-description"
                style={{
                  color: "#929292",
                }}
              >
                Have an inquiry or some feedback for us?
                <br
                  style={{
                    color: "#929292",
                  }}
                />
                Fill out the form below and our team will get in touch with you.
              </p>
            </div>
            <div className="row py-1">
              <div className="col-md-6 col-sm-12">
                <GetInTInput
                  text="First name"
                  bordr="input-styles"
                  name="fisrt_Name"
                  onChange={formik.handleChange}
                  value={formik.values.fisrt_Name}
                  icon={<FaUser />}
                />
                <div className="error-message mt-2">
                  {formik.touched.fisrt_Name && formik.errors.fisrt_Name}
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <GetInTInput
                  text="Last name"
                  bordr="input-styles"
                  name="last_Name"
                  onChange={formik.handleChange}
                  value={formik.values.last_Name}
                  icon={<FaUser />}
                />
                <div className="error-message mt-2">
                  {formik.touched.last_Name && formik.errors.last_Name}
                </div>
              </div>
            </div>
            <div className="row py-1">
              <div className="col-md-6 col-sm-12">
                <GetInTInput
                  text="Email address"
                  bordr="input-styles"
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  icon={<FaEnvelope />}
                />
                <div className="error-message mt-2">
                  {formik.touched.email && formik.errors.email}
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <GetInTInput
                  text="Phone number"
                  bordr="input-styles"
                  name="phoneNumber"
                  onChange={formik.handleChange}
                  value={formik.values.phoneNumber}
                  icon={<FaPhone />}
                />
                <div className="error-message mt-1">
                  {formik.touched.phoneNumber && formik.errors.phoneNumber}
                </div>
              </div>
            </div>
            <div className="row py-2">
              <div className="col-12">
                <Textareas
                  name="additional_details"
                  onChange={formik.handleChange}
                  value={formik.values.additional_details}
                />
              </div>
            </div>
            <div className="row my-3">
              <div className="col-md-12 col-sm-6">
                <button
                  type="submit"
                  className="btn"
                  style={{
                    fontWeight: 500,
                    padding: "10px 20px",
                    background: "#fbc344",
                    border: "2px solid #fbc344",
                  }}
                >
                  {propertyState?.isLoading2 ? (
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    "GET IN TOUCH"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
