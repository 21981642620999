import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Header from "../Header/Header";
import ExampleCarouselImage from "../../assets/images/hightlight.jpg";
import "../Hero/Hero.css";
import "./style_hero.css";
import "./Zoho_bigin.css";

const Hero_section = ({ ongoingProjs }) => {
  const [index, setIndex] = useState(0);
  const [showModal, setShowModal] = useState(false); // Modal state

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div id="home_id" className="text-white">
      <section className="hero-wrapper">
        <Header />

        <Carousel activeIndex={index} onSelect={handleSelect} interval={3000}>
          {ongoingProjs && ongoingProjs.length > 0
            ? ongoingProjs.slice(0, 7).map((project) => (
                <Carousel.Item key={project?._id}>
                  <div
                    className="project-slide"
                    style={{
                      backgroundImage: `url(${
                        project?.images[1] ||
                        "https://res.cloudinary.com/dnd6g0t90/image/upload/v1724427783/yin5qnwr3pezhwfxll6y.jpg"
                      })`,
                    }}
                  >
                    <div className="gradient-overlay">
                      <div className="caption-content mx-5">
                        <div className="description">
                          <h3>{project?.title}</h3>
                          <p>{project?.short_description}</p>
                        </div>
                        <div className="form-container m-2 mt-5">
                          <form className="project-form p-4">
                            {/* <h4>Request a Call Back</h4> */}
                            {/* <input
                              type="text"
                              placeholder="Name"
                              onClick={handleShowModal}
                            />
                            <input
                              type="text"
                              placeholder="Phone Number"
                              onClick={handleShowModal}
                            /> */}
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handleShowModal}
                            >
                              REQUEST A CALL BACK
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>
              ))
            : [1, 2, 3, 4].map((_, index) => (
                <Carousel.Item key={index}>
                  <div
                    className="project-slide"
                    style={{
                      backgroundImage: `url("https://res.cloudinary.com/dnd6g0t90/image/upload/v1724427783/yin5qnwr3pezhwfxll6y.jpg")`,
                    }}
                  >
                    <div
                      className="gradient-overlay"
                      style={{ paddingTop: "4.6rem" }}
                    >
                      <div className="caption-content mx-5 pb-4">
                        <div className="description">
                          <h3>4-Bedroom Semi Detached Homes</h3>
                          <p>
                            Unwind in a private oasis featuring a sparkling
                            swimming pool, perfect for relaxation and
                            entertainment. The commitment to sustainability is
                            evident in the incorporation of eco-friendly
                            features that reduce user's environmental impact
                            without compromising on luxury.
                          </p>
                          <p>
                            Discover the epitome of modern living in a home that
                            seamlessly blends sophistication with
                          </p>
                        </div>
                        <div className="form-container m-2">
                          <form className="project-form p-4">
                            <h4 id="h-font" style={{ color: "#fff" }}>
                              Request a Call Back
                            </h4>
                            <input
                              type="text"
                              placeholder="First Name"
                              onClick={handleShowModal}
                            />
                            <input
                              type="text"
                              placeholder="Last Name"
                              onClick={handleShowModal}
                            />
                            <input
                              type="text"
                              placeholder="Phone Number"
                              onClick={handleShowModal}
                            />
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handleShowModal}
                            >
                              MAKE AN ENQUIRY
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>
              ))}
        </Carousel>
      </section>

      {/* Modal for Zoho Bigin Form */}
      <Modal
        style={{
          marginTop: "-3rem",
          // marginRight: "4rem",
          // marginLeft: "3rem",
          height: "100vh",
        }}
        show={showModal}
        onHide={handleCloseModal}
        centered
        size="lg"
      >
        <Modal.Header
          closeButton
          style={{
            padding: "0px",
          }}
        >
          <Modal.Title
            style={{
              marginLeft: "2rem",
              padding: "0px",
            }}
          >
            Request a Call Back
          </Modal.Title>
        </Modal.Header>
        {/* <Modal.Body> */}
        <iframe
          width="100%"
          height="400px"
          src="https://us.bigin.online/org719228854/forms/webpage-for-project"
          style={{ border: "none" }}
        ></iframe>
      </Modal>
    </div>
  );
};

export default Hero_section;
