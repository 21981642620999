import React from "react";
import "./LocationProps.css";
import LocationSlider from "../Slider/LocationSlider";
import { Link } from "react-router-dom";

const LocationProps = ({ uniqueLocations }) => {
  return (
    <div className="container location-props">
      <div className="row">
        <div className="col-md-6">
          <h2 className="card-title-1">Properties by location</h2>
        </div>
        <div className="col-md-6 d-flex justify-content-end">
          <Link to="/properties">
            {" "}
            <button
              type="button"
              className="btn btn-primary text-dark"
              style={{
                background: "#fbc344",
                border: "2px solid #fbc344",
                fontWeight: "bold",
              }}
            >
              View All Properties
            </button>
          </Link>
        </div>
      </div>
      <div className="row text-align-right div_1">
        <div className="col-12">
          {<LocationSlider uniqueLocations={uniqueLocations} />}
          {/* {uniqueLocations?.length > 0 ? (
            <LocationSlider uniqueLocations={uniqueLocations} />
          ) : (
            <h4
              style={{
                margin: "8rem",
              }}
            >
              Please! Check Back
            </h4>
          )
           } */}
        </div>
      </div>
    </div>
  );
};

export default LocationProps;
