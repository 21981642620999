import React, { useEffect, useState } from "react";

import ApartmentAvailable from "../components/ApartmentAvailable/ApartmentAvailable";
import FindProperties from "../components/FindProperties/FindProperties";
import Footer from "../components/Footer/Footer";
import GetInTouch from "../components/GetInTouch/GetInTouch";
import Hero from "../components/Hero/Hero";
import LocationProps from "../components/LocationProps/LocationProps";
import HomeBlog from "../components/WeeklyProps/HomeBlog";
import WeeklyProps from "../components/WeeklyProps/WeeklyProps";
import FloatWhatsapp from "../components/FloatWhatsapp/FloatWhatsapp";
import { useDispatch, useSelector } from "react-redux";
import {
  allProject,
  // allBlog,
  // allProject,
  allProperty,
  fetchFeatProject,
  resetState,
} from "../features/Property/propertySlice";
import Preloader from "../components/Preloader/Preloader";
import axios from "axios";
import Featured_Projects from "../components/Home_Page_Addition/Featured_Projects";
import About_section from "../components/Home_Page_Addition/About_section";
import Hero_section from "../components/Home_Page_Addition/Hero_section";
import Search_home from "../components/FindProperties/Search_home";
import New_Home_Blog from "../components/Home_Page_Addition/New_Home_Blog";
import Home_footer from "../components/Home_Page_Addition/Home_footer";

const Home = () => {
  const dispatch = useDispatch();
  const propertyState = useSelector((state) => state.property);
  const projectState = useSelector((state) => state.property);

  // const projs = projectState?.projects?.allProject || [];
  const propertys = propertyState?.properties?.allProperty || [];
  const projects = projectState?.projects?.allProject || [];

  // filtering projectState
  const completedProjs = projects
    .filter((proj) => {
      return proj.category === "completed";
    })
    .reverse();
  const ongoingProjs = projects
    .filter((proj) => {
      return proj.category === "ongoing";
    })
    .reverse();

  // filtering props
  const propertyOfTheWeek = propertys
    .filter((property) => {
      return property.tag === "property of the week";
    })
    .reverse();
  const availableLuxury = propertys
    .filter((property) => {
      return property.tag === "available luxury";
    })
    .reverse();

  const uniqueLocations = [
    ...new Set(
      propertys.map((property) => property.location.trim().toLowerCase())
    ),
  ];

  useEffect(() => {
    dispatch(resetState());
    dispatch(allProperty());
    // dispatch(fetchFeatProject());
    dispatch(allProject());
  }, [dispatch]);
  //
  const [isLoading, setIsLoading] = useState(true);

  const propertyLoading = useSelector((state) => state.property.isLoading);
  // const blogsLoading = useSelector((state) => state.property.isLoadingBlog);
  const projectsLoading = useSelector((state) => state.property.isLoadingProj);

  useEffect(() => {
    setIsLoading(propertyLoading || projectsLoading);
  }, [propertyLoading, projectsLoading]);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 640);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 640);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [homePage, setHomePage] = useState(true);
  const [homeBg, setHomeBg] = useState(true);

  useEffect(() => {
    // Scroll to the top when the component is mounted
    window.scrollTo(0, 0);
  }, []);

  const url_str = "property";
  const url_strblog = "selected-blog";

  // test
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://blogadmin.joetoniruest.com/wp-json/wp/v2/posts?_embed=wp:featuredmedia,categories"
        );
        setPosts(response.data);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return <Preloader />;
  }

  return (
    <>
      {/* <Hero featuredPrj={projectState?.featProject?.project} /> */}
      <Hero_section ongoingProjs={ongoingProjs} />
      <Featured_Projects completedProjs={completedProjs} />
      <About_section />
      <Search_home />
      <LocationProps uniqueLocations={uniqueLocations} />
      <New_Home_Blog
        blogs={posts}
        homeBg={homeBg}
        homePage={homePage}
        url_strblog={url_strblog}
      />
      <GetInTouch homePage={homePage} />
      <Home_footer />
      {/* <WeeklyProps
        propertyOfTheWeek={propertyOfTheWeek}
        homeBg={homeBg}
        homePage={homePage}
        url_str={url_str}
      /> */}
      {/* <ApartmentAvailable
        availableLuxury={availableLuxury}
        homePage={homePage}
      /> */}
      {/* <HomeBlog
        blogs={posts}
        homeBg={homeBg}
        homePage={homePage}
        url_strblog={url_strblog}
      /> 
      <Footer />
      <FloatWhatsapp />*/}
    </>
  );
};

export default Home;
